import Papa from "papaparse";
import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Button, Col, Row, Tooltip } from "reactstrap";
import { refreshAccountApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useAccount } from "../../Context/AccountContext";
import useDebounce from "../../Hooks/useDebounce";
import { toMilliseconds } from "../../Utils";
import CommonPagination from "../Common/CommonPagination";
import { handleRefresh } from "../Common/CustomFunctions";
import PageLimitDropdown from "../Common/PageLimitDropdown";
import AccountDataTable from "./AccountDataTable";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";
import ImportModal from "./ImportModal";

const AccountContainer = () => {
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const { editModal, importModal, toggleEdit, searchTerm, setSearchTerm, deleteModal } = useAccount();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [editMode, setEditMode] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [remainingTime, setRemainingTime] = useState();

  const params = { page, limit, search, sortOrder: sortOrder ? "asc" : "desc", sortColumn };
  const { refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: true } });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const calculateRemainingTime = (lastRefreshTime) => {
    const now = new Date().getTime();
    const timeSinceLastRefresh = now - lastRefreshTime;
    const remainingTime = REFRESH_INTERVAL - timeSinceLastRefresh;

    if (remainingTime > 0) {
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }
    return null;
  };

  // Refresh button will enable after given REFRESH_INTERVAL time once clicked
  useEffect(() => {
    const lastRefreshTime = localStorage.getItem("lastRefreshTime");
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  const { isLoading: isLoadingGetData, data: accountData, refetch: refetchGetData } = useGetApi({ url: "/api/getAccounts", params, options: { refetchOnWindowFocus: false, enabled: !!Object.keys(params).length } });

  useEffect(() => {
    if (accountData) {
      setTotalPages(accountData.totalPages);
    }
  }, [accountData]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    if (search.trim()?.length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  const addAccount = () => {
    setEditMode(false);
    toggleEdit();
  };

  const exportToCSV = () => {
    if (accountData && accountData.data && accountData.data.length > 0) {
      const csv = Papa.unparse(accountData.data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "accounts.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <Fragment>
      <section className="common-table">
        <Row className="mb-3 g-3">
          <Col>
            <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
          </Col>
          <Col className="search-box">
            <div className="common-flex">
              <p className="mb-0">Search :</p>
              <div className="d-flex align-items-center">
                <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target?.value)} />
              </div>
            </div>
            <Button color="transparent" className="btn-primary export-btn" onClick={exportToCSV}>
              <i className="fa-solid fa-file-export"></i>
              Export
            </Button>
            <Button color="primary border border-1" onClick={addAccount}>
              <Plus size={15} /> Add Account
            </Button>
            <span id="refresh-tooltip">
              <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
                <div className="light-box" onClick={() => handleRefresh(refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime)}>
                  <i className="fa-solid fa-rotate"></i>
                </div>
              </Button>
            </span>
            <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
              {/* {tooltipContent} */}
              <p>
                {tooltipContent} <b>{remainingTime}</b>{" "}
              </p>
            </Tooltip>
          </Col>
        </Row>
        <div className="custom-scrollbar table-responsive">
          <AccountDataTable accountData={accountData} handleSort={handleSort} isLoadingGetData={isLoadingGetData} setEditMode={setEditMode} sortOrder={sortOrder} />
        </div>
        {!isLoadingGetData && accountData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {deleteModal && <DeleteModal refetchTransaction={refetchGetData} />}
      {editModal && <EditModal refetchTransaction={refetchGetData} isEditMode={editMode} toggleEdit={toggleEdit} />}
      {importModal && <ImportModal refetchGetData={refetchGetData} />}
    </Fragment>
  );
};

export default AccountContainer;
