import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col } from "reactstrap";

const TopTreasury = ({ staticsData }) => {
  const categories = staticsData?.treasuryRecipients.map((recipient) => recipient.address);
  const spendData = staticsData?.treasuryRecipients.map((recipient) => parseFloat(recipient.spend));

  const topTreasuryChart = {
    series: [
      {
        name: "Amount",
        type: "column",
        data: spendData,
      },
    ],
    chart: {
      height: 400,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1],
      curve: "smooth",
      dashArray: [0],
    },

    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#8884d9"],
    fill: {
      type: "solid",
      gradient: {
        shade: "dark",
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(82, 82, 108, 0.2)",
      strokeDashArray: 3,
      position: "back",
    },
    legend: {
      show: false,
    },

    markers: {
      size: 0,
    },
    tooltip: {
      enabled: true,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: ["#52526c"],
        },
        formatter: (value) => {
          return value?.length > 10 ? `${value?.slice(0, 4)}...${value?.slice(-4)}` : value;
        },
      },
      axisBorder: {
        show: true,
        color: "#52526c",
      },
      axisTicks: {
        show: true,
        color: "#52526c",
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "#52526c",
      },
      axisTicks: {
        show: true,
        color: "#52526c",
      },
      min: 0,
      title: {
        text: "Amount",
      },
    },
  };

  return (
    <Col lg="6">
      <Card className="common-chart">
        <CardHeader className="pb-0">
          <h4>Top Treasury Recipients</h4>
        </CardHeader>
        <CardBody className="pt-0">
          <ReactApexChart type="line" options={topTreasuryChart} series={topTreasuryChart.series} height={400} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default TopTreasury;
