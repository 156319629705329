import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createAccountApi, updateAccountApi } from "../../Api/ApiList";
import { performMutation } from "../../Api/PerformMutation";
import { useAccount } from "../../Context/AccountContext";

const EditModal = ({ refetchTransaction, isEditMode, toggleEdit }) => {
  const { editModal, editAccountData } = useAccount();
  const [loading, setLoading] = useState(false); // State to track loading status

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      address: "",
      spend: "",
      is_treasury: false,
      starting_balance: "",
      label: "",
    },
  });

  useEffect(() => {
    if (isEditMode && editAccountData) {
      setValue("id", editAccountData.id);
      setValue("address", editAccountData.address);
      setValue("label", editAccountData.label);
      setValue("spend", editAccountData.spend);
      setValue("is_treasury", editAccountData.is_treasury ? 1 : 0);
      setValue("starting_balance", editAccountData.starting_balance);
    } else {
      reset(); // Reset form for new account creation
    }
  }, [isEditMode, editAccountData, setValue, reset]);

  const handleAccount = async (data) => {
    setLoading(true);
    const apiEndpoint = isEditMode ? updateAccountApi : createAccountApi;
    const conditionalData = {
      id: data.id,
      label: data.label,
      is_treasury: data.is_treasury,
      address: data.address,
    };

    await performMutation(
      apiEndpoint,
      conditionalData,
      () => {
        refetchTransaction();
        reset();
        toggleEdit();
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(false);
  };

  const handleTreasuryChange = (e) => {
    setValue("is_treasury", e.target.checked ? 1 : 0);
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Account" : "Create Account"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleAccount)} noValidate>
        <ModalBody>
          <Row className="g-3">
            {isEditMode && (
              <Col sm="6">
                <FormGroup className="form-group">
                  <Label className="mb-2 form-label">No :</Label>
                  <input {...register("id")} disabled className="form-control search-transaction" type="text" placeholder="Enter number" />
                </FormGroup>
              </Col>
            )}
            {isEditMode && (
              <Col sm="6">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Label:</label>
                  <input {...register("label", { required: "Label is required" })} className={`form-control search-transaction ${errors.label ? "is-invalid" : ""}`} type="text" placeholder="Enter label" />
                  {errors.label && <div className="invalid-feedback">{errors.label.message}</div>}
                </FormGroup>
              </Col>
            )}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Address :</label>
                <input
                  {...register("address", { required: "Address is required" })}
                  className={`form-control search-transaction ${errors.address ? "is-invalid" : ""}`}
                  type="text"
                  disabled={isEditMode}
                  placeholder="Enter address" // Only enable this field for creation
                />
                {errors.address && <div className="invalid-feedback">{errors.address.message}</div>}
              </FormGroup>
            </Col>
            {!isEditMode && (
              <Col sm="6">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Label:</label>
                  <input {...register("label", { required: "Label is required" })} className={`form-control search-transaction ${errors.label ? "is-invalid" : ""}`} type="text" placeholder="Enter label" />
                  {errors.label && <div className="invalid-feedback">{errors.label.message}</div>}
                </FormGroup>
              </Col>
            )}
            {isEditMode && (
              <Col sm="6">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Spend :</label>
                  <input
                    {...register("spend")}
                    className="form-control search-transaction"
                    type="text"
                    placeholder="spend amount"
                    disabled={isEditMode} // Only enable this field for creation
                  />
                </FormGroup>
              </Col>
            )}
            <Col sm="6" className="d-flex align-items-center">
              <div className="check-input gap-2">
                <label htmlFor="treasury-switch " className="form-label mb-0">
                  Treasury
                </label>
                <FormGroup switch>
                  <Input type="switch" role="switch" onChange={handleTreasuryChange} checked={watch("is_treasury") === 1} />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            {isEditMode ? "Update" : "Create"} {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button
            color="light"
            className="border border-1"
            onClick={() => {
              toggleEdit();
              reset();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
