import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetApi } from "../Api/useGetApi";

import noNotifications from "./no-notify.svg";

const HeaderNotifications = () => {
  const { isLoading, data: notificationsData, refetch: refetchGetData } = useGetApi({ url: `/api/getAllNotification`, options: { refetchOnWindowFocus: false, enabled: false } });
  useEffect(() => {
    refetchGetData();
  }, []);

  const filteredNotifications = notificationsData?.data?.filter((notification) => !notification?.mark_as_read);

  return (
    <li>
      {filteredNotifications?.length ? <span className="badge badge-danger">{filteredNotifications?.length}</span> : null}
      <div className="light-box">
        <i className="fa-solid fa-bell"></i>
      </div>
      <ul className={`notify-dropdown ${!notificationsData?.data?.length ? "no-notify" : ""}`}>
        <li>
          <h6>Notifications</h6>
        </li>
        {notificationsData?.data?.length ? (
          notificationsData?.data?.slice(0, 4).map((item, i) => (
            <li>
              <div className="rounded-circle">
                <i className="fa-solid fa-dollar-sign fa-flip"></i>
              </div>
              <div>
                <p className="title">{item?.title}</p>
                <span>{item?.description}</span>
              </div>
            </li>
          ))
        ) : (
          <li>
            <div className="empty-notify">
              <img className="img-fluid" src={noNotifications} alt="no notification" />
              <span>There is no new notifications</span>
            </div>
          </li>
        )}
        {notificationsData?.data?.length ? (
          <li>
            <Link to="/notifications" className="btn btn-primary">
              Check All Notifications
            </Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

export default HeaderNotifications;
