import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useAuth } from "../Context/AuthContext";
import HeaderNotifications from "./HeaderNotifications";

const Layout = () => {
  const { logout } = useAuth();
  const location = useLocation();

  return (
    <div className={`page-wrapper`}>
      <div className={`page-body-wrapper`}>
        <div className="page-body">
          <Container fluid className="main-navbar d-flex justify-content-between container-fluid">
            <div className="custom-nav">
              <Link className={`btn ${location.pathname === "/transaction" ? "active" : ""}`} to="/transaction">
                <i className="fa-solid fa-money-bill-transfer"></i>
                Transaction
              </Link>
              <Link className={`btn ${location.pathname === "/account" ? "active" : ""}`} to="/account">
                <i className="fa-solid fa-user"></i>
                Account
              </Link>
              <Link className={`btn ${location.pathname === "/statistics" ? "active" : ""}`} to="/statistics">
                <i className="fa-solid fa-chart-line"></i>
                Statistics
              </Link>
              <Link className={`btn ${location.pathname === "/budget" ? "active" : ""}`} to="/budget">
                <i className="fa-solid fa-coins"></i>
                Budgeting
              </Link>
              <Link className={`btn ${location.pathname === "/bridge_transfer" ? "active" : ""}`} to="/bridge_transfer">
              <i className="fa-solid fa-hand-holding-dollar"></i>
                Bridge Transfers
              </Link>
            </div>
            <ul className="common-flex right-navbar">
              <HeaderNotifications />
              <li>
                <Button className="signout" color="danger" onClick={logout}>
                  Sign Out
                </Button>
              </li>
            </ul>
          </Container>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
