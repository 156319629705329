import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Tooltip } from "reactstrap";
import { refreshAccountApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useTransaction } from "../../Context/TransactionContext";
import useDebounce from "../../Hooks/useDebounce";
import { toMilliseconds } from "../../Utils";
import CommonPagination from "../Common/CommonPagination";
import { handleRefresh } from "../Common/CustomFunctions";
import PageLimitDropdown from "../Common/PageLimitDropdown";
import AdvanceFilter from "./AdvanceFilter";
import EditModal from "./EditModal";
import TransactionDataTable from "./TransactionDataTable";

const TransactionContainer = () => {
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("id");
  const [showFilter, setShowFilter] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [remainingTime, setRemainingTime] = useState();

  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    fromAddress: null,
    toAddress: null,
  });

  const { editModal, searchTerm, setSearchTerm } = useTransaction();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: transactionData, refetch: refetchGetData } = useGetApi({ url: "/api/getTransactions", params, options: { enabled: !!Object.keys(params).length } });
  const { data: refreshedData, refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: false } });

  const debouncedStartDate = useDebounce(advancedFilter.startDate, 500);
  const debouncedEndDate = useDebounce(advancedFilter.endDate, 500);
  const debouncedMinAmount = useDebounce(advancedFilter.minAmount, 500);
  const debouncedMaxAmount = useDebounce(advancedFilter.maxAmount, 500);
  const debouncedFromAddress = useDebounce(advancedFilter.fromAddress, 500);
  const debouncedToAddress = useDebounce(advancedFilter.toAddress, 500);

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // send advance filter params in api
  useEffect(() => {
    // Check if `from_address` and `to_address` are not empty
    if (debouncedFromAddress?.trim() !== "" && debouncedToAddress?.trim() !== "") {
      setParams({
        ...params,
        startDate: debouncedStartDate,
        endDate: debouncedEndDate,
        minAmount: debouncedMinAmount,
        maxAmount: debouncedMaxAmount,
        from_address: debouncedFromAddress,
        to_address: debouncedToAddress,
      });
    } else {
      // Set params without address fields if they are empty
      setParams({
        ...params,
        startDate: debouncedStartDate,
        endDate: debouncedEndDate,
        minAmount: debouncedMinAmount,
        maxAmount: debouncedMaxAmount,
        from_address: null, // or null, depending on how your API handles these values
        to_address: null, // or null
      });
    }
  }, [debouncedStartDate, debouncedEndDate, debouncedFromAddress, debouncedToAddress, debouncedMinAmount, debouncedMaxAmount]);

  // total pages of data
  useEffect(() => {
    if (transactionData) {
      setTotalPages(transactionData.totalPages);
    }
  }, [transactionData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      sortOrder: sortOrder ? "asc" : "desc",
      sortColumn: sortColumn,
    }));
  }, [search, page, limit, sortOrder, sortColumn]);

  useEffect(() => {
    if (search.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, sortColumn, isLoadingGetData, sortOrder, refetchGetData]);

  // Calculate the remaining time for the countdown tooltip
  const calculateRemainingTime = (lastRefreshTime) => {
    const now = new Date().getTime();
    const timeSinceLastRefresh = now - lastRefreshTime;
    const remainingTime = REFRESH_INTERVAL - timeSinceLastRefresh;

    if (remainingTime > 0) {
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }
    return null;
  };

  // Refresh button will enable after given REFRESH_INTERVAL time once clicked
  useEffect(() => {
    const lastRefreshTime = localStorage.getItem("lastRefreshTime");
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  return (
    <Fragment>
      <section className="common-table">
        <Row className="mb-3 g-3">
          <Col>
            <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
          </Col>
          <Col className="search-box">
            <div className="common-flex">
              <p className="mb-0">Search :</p>
              <div className="d-flex align-items-center">
                <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <div className="light-box" onClick={() => setShowFilter(!showFilter)}>
              {showFilter ? <i className="fa-solid fa-xmark" /> : <i className="fa-solid fa-filter" />}
            </div>
            <span id="refresh-tooltip" style={{cursor:'pointer'}}>
            <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
              <div className="light-box"  onClick={() => handleRefresh(refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL,setTooltipContent,calculateRemainingTime,setRemainingTime)}>
                <i className="fa-solid fa-rotate"></i>
              </div>
            </Button>
            </span>
            <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
              <p>{tooltipContent} <b >{remainingTime}</b> </p>
            </Tooltip>
          </Col>
        </Row>
        {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} /> : null}
        <div className="custom-scrollbar table-responsive">
          <TransactionDataTable handleSort={handleSort} isLoadingGetData={isLoadingGetData} sortOrder={sortOrder} transactionData={transactionData} />
        </div>
        {!isLoadingGetData && transactionData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {editModal && <EditModal refetchTransaction={refetchGetData} />}
      {/* {importModal && <ImportModal refetchGetData={refetchGetData} />} */}
    </Fragment>
  );
};

export default TransactionContainer;

// import React, { Fragment, useEffect, useState } from "react";
// import { Button, Col, Row, Tooltip } from "reactstrap";
// import { refreshAccountApi } from "../../Api/ApiList";
// import { useGetApi } from "../../Api/useGetApi";
// import { useTransaction } from "../../Context/TransactionContext";
// import useDebounce from "../../Hooks/useDebounce";
// import { toMilliseconds } from "../../Utils";
// import CommonPagination from "../Common/CommonPagination";
// import { handleRefresh } from "../Common/CustomFunctions";
// import PageLimitDropdown from "../Common/PageLimitDropdown";
// import AdvanceFilter from "./AdvanceFilter";
// import EditModal from "./EditModal";
// import TransactionDataTable from "./TransactionDataTable";

// const TransactionContainer = () => {
//   const REFRESH_INTERVAL = toMilliseconds(1, 0, 0); // 1 hour
//   const [totalPages, setTotalPages] = useState();
//   const [page, setPage] = useState(1);
//   const [limit, setLimit] = useState(25);
//   const [sortOrder, setSortOrder] = useState(true);
//   const [sortColumn, setSortColumn] = useState("id");
//   const [showFilter, setShowFilter] = useState(false);
//   const [tooltipOpen, setTooltipOpen] = useState(false);
//   const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
//   const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
//   const toggle = () => setTooltipOpen(!tooltipOpen);

//   const [advancedFilter, setAdvancedFilter] = useState({
//     startDate: null,
//     endDate: null,
//     minAmount: null,
//     maxAmount: null,
//     fromAddress: null,
//     toAddress: null,
//   });

//   const { editModal, searchTerm, setSearchTerm } = useTransaction();
//   const search = useDebounce(searchTerm?.trim(), 500);
//   const [params, setParams] = useState({});
//   const { isLoading: isLoadingGetData, data: transactionData, refetch: refetchGetData } = useGetApi({ url: "/api/getTransactions", params, options: { enabled: !!Object.keys(params).length } });
//   const { data: refreshedData, refetch: refreshData } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: false } });

//   const debouncedStartDate = useDebounce(advancedFilter.startDate, 500);
//   const debouncedEndDate = useDebounce(advancedFilter.endDate, 500);
//   const debouncedMinAmount = useDebounce(advancedFilter.minAmount, 500);
//   const debouncedMaxAmount = useDebounce(advancedFilter.maxAmount, 500);
//   const debouncedFromAddress = useDebounce(advancedFilter.fromAddress, 500);
//   const debouncedToAddress = useDebounce(advancedFilter.toAddress, 500);

//   const handleSort = (column) => {
//     setSortColumn(column);
//     setSortOrder(!sortOrder);
//   };

//   useEffect(() => {
//     if (searchTerm.trim() === "") {
//       setPage(1);
//     }
//   }, [searchTerm]);

//   // send advance filter params in api
//   useEffect(() => {
//     // Check if `from_address` and `to_address` are not empty
//     if (debouncedFromAddress?.trim() !== "" && debouncedToAddress?.trim() !== "") {
//       setParams({
//         ...params,
//         startDate: debouncedStartDate,
//         endDate: debouncedEndDate,
//         minAmount: debouncedMinAmount,
//         maxAmount: debouncedMaxAmount,
//         from_address: debouncedFromAddress,
//         to_address: debouncedToAddress,
//       });
//     } else {
//       // Set params without address fields if they are empty
//       setParams({
//         ...params,
//         startDate: debouncedStartDate,
//         endDate: debouncedEndDate,
//         minAmount: debouncedMinAmount,
//         maxAmount: debouncedMaxAmount,
//         from_address: null, // or null, depending on how your API handles these values
//         to_address: null, // or null
//       });
//     }
//   }, [debouncedStartDate, debouncedEndDate, debouncedFromAddress, debouncedToAddress, debouncedMinAmount, debouncedMaxAmount]);

//   // total pages of data
//   useEffect(() => {
//     if (transactionData) {
//       setTotalPages(transactionData.totalPages);
//     }
//   }, [transactionData]);

//   useEffect(() => {
//     setParams((prevParams) => ({
//       ...prevParams,
//       page,
//       limit,
//       search,
//       sortOrder: sortOrder ? "asc" : "desc",
//       sortColumn: sortColumn,
//     }));
//   }, [search, page, limit, sortOrder, sortColumn]);

//   useEffect(() => {
//     if (search.trim().length > 0) {
//       isLoadingGetData && refetchGetData();
//     }
//   }, [search, page, limit, sortColumn, isLoadingGetData, sortOrder, refetchGetData]);

//   // Calculate the remaining time for the countdown tooltip
//   const calculateRemainingTime = (lastRefreshTime) => {
//     const now = new Date().getTime();
//     const timeSinceLastRefresh = now - lastRefreshTime;
//     const remainingTime = REFRESH_INTERVAL - timeSinceLastRefresh;

//     if (remainingTime > 0) {
//       const hours = Math.floor(remainingTime / (1000 * 60 * 60));
//       const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

//       return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
//     }
//     return null;
//   };

//   // Refresh button will enable after given REFRESH_INTERVAL time once clicked
//   useEffect(() => {
//     const lastRefreshTime = localStorage.getItem("lastRefreshTime");
//     if (lastRefreshTime) {
//       const now = new Date().getTime();
//       const timeSinceLastRefresh = now - lastRefreshTime;

//       if (timeSinceLastRefresh < REFRESH_INTERVAL) {
//         setIsRefreshDisabled(true);
//         const intervalId = setInterval(() => {
//           const remainingTime = calculateRemainingTime(lastRefreshTime);
//           if (remainingTime) {
//             setTooltipContent(`Refresh available in ${remainingTime}`);
//           } else {
//             setIsRefreshDisabled(false);
//             setTooltipContent("Refresh");
//             clearInterval(intervalId);
//           }
//         }, 1000);

//         return () => clearInterval(intervalId);
//       }
//     }
//   }, [REFRESH_INTERVAL]);

//   return (
//     <Fragment>
//       <section className="common-table">
//         <Row className="mb-3 g-3">
//           <Col>
//             <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
//           </Col>
//           <Col className="search-box">
//             <div className="common-flex">
//               <p className="mb-0">Search :</p>
//               <div className="d-flex align-items-center">
//                 <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
//               </div>
//             </div>
//             <div className="light-box" onClick={() => setShowFilter(!showFilter)}>
//               {showFilter ? <i className="fa-solid fa-xmark" /> : <i className="fa-solid fa-filter" />}
//             </div>
//             <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
//               <div className="light-box" id="refresh-tooltip" onClick={() => handleRefresh(refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL)}>
//                 <i className="fa-solid fa-rotate"></i>
//               </div>
//             </Button>
//             <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
//               {tooltipContent}
//             </Tooltip>
//           </Col>
//         </Row>
//         {showFilter ? <AdvanceFilter refreshedData={refreshedData} refreshData={refreshData} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} /> : null}
//         <div className="custom-scrollbar table-responsive">
//           <TransactionDataTable handleSort={handleSort} isLoadingGetData={isLoadingGetData} sortOrder={sortOrder} transactionData={transactionData} />
//         </div>
//         {!isLoadingGetData && transactionData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
//       </section>
//       {editModal && <EditModal refetchTransaction={refetchGetData} />}
//       {/* {importModal && <ImportModal refetchGetData={refetchGetData} />} */}
//     </Fragment>
//   );
// };

// export default TransactionContainer;
