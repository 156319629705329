import { Formik } from "formik";
import React, { Fragment } from "react";
import { CSVLink } from "react-csv";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import { importAccountApi } from "../../Api/ApiList";
import useImport from "../../Api/useImport";
import { useAccount } from "../../Context/AccountContext";
import FileUploadBrowser from "./FileUploadBrowser";

const YupObject = (schemaObject) => Yup.object().shape(schemaObject);
const requiredSchema = Yup.mixed().required();

const ImportModal = ({ refetchGetData }) => {
  const { importModal, toggleImport } = useAccount();
  const csvData = [["block_height", "event_id", "from", "to", "value", "timestamp"]];

  const { mutate, isPending } = useImport({ url: importAccountApi, refetch: refetchGetData, extraFun: toggleImport });

  return (
    <Modal centered isOpen={importModal} toggle={toggleImport}>
      <ModalHeader toggle={toggleImport}>Import File</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ data: null }}
          validationSchema={YupObject({ data: requiredSchema })}
          onSubmit={async (values, { resetForm }) => {
            let formData = new FormData();
            if (values.data) {
              formData.append("file", values.data);
            }
            mutate(formData);
            resetForm();
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="custom-scroll">
                <div>
                  <div className="content-section">
                    <div className="import-content">
                      <div>
                        <h5>
                          {!values["data"] && (
                            <Fragment>
                              Drop files here, paste
                              <p>or</p>
                            </Fragment>
                          )}
                          <FileUploadBrowser errors={errors} name="data" type="file" multiple={false} values={values} setFieldValue={setFieldValue} accept=".csv" />
                        </h5>
                      </div>
                    </div>
                  </div>
                  {!values["data"] && (
                    <p>
                      * Please download Example CSV
                      <CSVLink className="import-file" filename={"data.csv"} data={csvData}>
                        Here
                      </CSVLink>
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer p-0 d-flex gap-2">
                {values.data && (
                  <Button color="light" className="border clear-btn border-1 px-3" onClick={() => setFieldValue("data", null)}>
                    Clear
                  </Button>
                )}
                <div className="btn-loader loader-btn">
                  <Button type="submit" color="primary" disabled={isPending ? true : false} className="px-3 spinning-loader">
                    Submit {isPending && <div className="loader"></div>}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ImportModal;
