import React, { Fragment, useState } from "react";
import { Badge, Tooltip } from "reactstrap";

const CommonTooltip = ({ placement, id, content, children, color, redirectLink, isBadge = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleCopy = () => {
    if (redirectLink) {
      // Open the link in a new tab using window.open
      window.open(`${redirectLink}/${content}`, "_blank");
    }
  };

  return (
    <>
      {!isBadge ? (
        <Badge color={color} id={id}>
          {children}
        </Badge>
      ) : (
        <span id={id}>
        { children }
        </span>
      )}
      <Tooltip placement={placement} autohide={false} isOpen={tooltipOpen} target={id} toggle={toggle} onClick={handleCopy}>
        {copied ? "Copied!" : content}
      </Tooltip>
    </>
  );
};

export default CommonTooltip;
