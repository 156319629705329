import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

// Encrypt function that takes a plain object as input
export const encrypt = (obj) => {
  // Generate a random nonce value
  const nonce = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.REACT_APP_CRYPTO_SECRETE_KEY, { iv: nonce });

  return {
    encryptedData: encrypted.toString(),
    nonce: nonce.toString(),
  };
};

// Decrypt function that takes the encrypted string as input
export const decrypt = (encryptedData, nonce) => {
  try {
    // Convert the nonce string to a WordArray
    const nonceWordArray = CryptoJS.enc.Hex.parse(nonce);

    // Decrypt the data using the key and the nonce
    const decrypted = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTO_SECRETE_KEY, { iv: nonceWordArray });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    toast.error(error.message);
  }
};
