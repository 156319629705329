import React from "react";
import { Container, Row } from "reactstrap";
import { useGetApi } from "../../Api/useGetApi";
import InnerLoader from "../../Layout/InnerLoader";
import RecentBank from "./BottomCard/RecentBank";
import RecentTreasury from "./BottomCard/RecentTreasury";
import TopBankTransfer from "./ColumnChart/TopBankTransfer";
import TopTreasury from "./ColumnChart/TopTreasury";
import BankBalance from "./LineChart/BankBalance";
import TreasuryBalance from "./LineChart/TreasuryBalance";
import SmallCard from "./SmallCard/SmallCard";

const DashboardContainer = () => {
  const { isPending: pendingGetStatics, data: staticsData } = useGetApi({ url: "/api/getStatics",options:{refetchOnMount :true}});

  if (pendingGetStatics) {
    return <InnerLoader />;
  }

  return (
    <Container fluid className="dashboard-section">
      <Row className="g-4 treasury-stats">
        <TreasuryBalance />
        <BankBalance />
        <SmallCard staticsData={staticsData} />
        <TopTreasury staticsData={staticsData} />
        <TopBankTransfer />
        <RecentTreasury staticsData={staticsData} />
        <RecentBank />
      </Row>
    </Container>
  );
};
export default DashboardContainer;
