import React from "react";
import { topBankTransferChart } from "../../../Data/Dashboard/DashboardChart";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const TopBankTransfer = () => {
  return (
    <Col lg="6">
      <Card className="common-chart">
        <CardHeader className="pb-0"><h4>Top Bank Transfer Recipients</h4></CardHeader>
        <CardBody className="pt-0">
          <ReactApexChart
            type="line"
            options={topBankTransferChart}
            series={topBankTransferChart.series}
            height={400}
          />
        </CardBody>
      </Card>
    </Col>
  );
};
export default TopBankTransfer;
