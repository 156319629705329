import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { formatNumber } from "../../../Utils";

const SmallCard = ({staticsData}) => {
  return (
    <Col lg={12}>
      <Row className="g-3">
        <Col md={4} sm={6}>
          <Card>
            <CardHeader>
              <h4 className="mb-0">Total Transfers</h4></CardHeader>
            <CardBody className="pt-0">
              <h2>
              {staticsData?.data?.total_transfers}
                </h2></CardBody>
          </Card>
        </Col>
        <Col md={4} sm={6}>
          <Card>
            <CardHeader><h4 className="mb-0">Treasury Balance</h4></CardHeader>
            <CardBody className="pt-0"><h2>{formatNumber(staticsData?.data?.treasury_balance)}</h2></CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardHeader><h4 className="mb-0">Bank Balance</h4></CardHeader>
            <CardBody className="pt-0"><h2>1,234</h2></CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
export default SmallCard;
