import React, { Fragment } from "react";
import { Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { useAccount } from "../../Context/AccountContext";
import InnerLoader from "../../Layout/InnerLoader";
import { formatAmount, handleCopyCode, truncateMiddle } from "../../Utils";
import CommonTooltip from "../Common/CommonTooltip";

const AccountDataTable = ({ accountData, setEditMode, handleSort, sortOrder, isLoadingGetData }) => {
  const { toggleEdit, setEditAccountData, toggleDelete } = useAccount();

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = accountData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditAccountData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = accountData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditAccountData(singleEditData);
    }
  };
  return (
    <Table bordered className="account-table">
      <thead>
        <tr>
          <th className="head-text">
            <p className="header-name">
              No.
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>

          <th className="head-text">
            <p className="header-name">
              Label
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">Address</p>
          </th>

          <th className="head-text">
            <p className="header-name">
              Spend
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">
              Balance
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">
               Treasury
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">Action</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoadingGetData ? (
          <tr>
            <td colSpan="10" className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : (
          <>
            {accountData?.data?.length > 0 ? (
              accountData?.data?.map((item, i) => (
                <tr key={item.id}>
                  <td scope="row">{i + 1}</td>
                  <td>
                    <div className="copy-account">
                      {item.label ? (
                        <CommonTooltip placement="right" id={`TooltipExample-from-${item.id}`} content={item.address} color="danger">
                          {item.label}
                        </CommonTooltip>
                      ) : (
                        item.label
                      )}
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.address)} />
                    </div>
                  </td>
                  <td>
                    <div className="copy-account">
                      <Link to={`https://avail.subscan.io/account/${item.address}`} target="_blank">
                        {truncateMiddle(item.address, 12)}
                      </Link>
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.address)} />
                    </div>
                  </td>
                  <td>{formatAmount(item.spend)}</td>
                  <td>
                    {
                      <CommonTooltip
                        isBadge={true}
                        placement="right"
                        id={`Tooltip-balance-${item.id}`}
                        content={
                          <Fragment>
                            <span>
                              <strong>Locked: </strong> {item?.amountFrozenRounded}
                            </span>
                            <span>
                              <strong>Amount:</strong> {item?.amountRounded}
                            </span>
                            <span>
                              <strong>Total:</strong> {item?.amountTotalRounded}
                            </span>
                          </Fragment>
                        }
                      >
                        <u> {item?.amountTotalRounded ? formatAmount(item?.amountTotalRounded) : item?.amountTotalRounded}</u>
                      </CommonTooltip>
                    }
                  </td>
                  <td> {item.is_treasury ? <i className="fa-solid fa-circle-check treasury-right"></i> : <i className="fa-solid fa-circle-xmark treasury-cancel"></i>}</td>
                  <td>
                    <div className="common-flex justify-content-center">
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center" onClick={() => handleEdit(item.id)}>
                          <Edit size={20} />
                        </Button>
                      </div>
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center trash-icon" onClick={() => handleDelete(item.id)}>
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan="10" className="text-center no-found">
                  No data found
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default AccountDataTable;
