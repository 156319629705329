import React, { createContext, useContext, useState } from "react";

const BudgetContext = createContext();

export const BudgetProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [editBudgetData, setEditBudgetData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  // const [markAsRead, setMarkAsRead] = useState("");

  return <BudgetContext.Provider value={{ editModal, setEditModal, deleteModal, toggleDelete, setDeleteModal, toggleEdit, importModal, setImportModal, toggleImport, setEditBudgetData, editBudgetData, searchTerm, setSearchTerm }}>{children}</BudgetContext.Provider>;
};

export const useBudget = () => {
  return useContext(BudgetContext);
};
