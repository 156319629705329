import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createBudgetApi, updateBudgetApi } from "../../Api/ApiList";
import { performMutation } from "../../Api/PerformMutation";
import { useGetApi } from "../../Api/useGetApi";
import { useBudget } from "../../Context/BudgetContext";

const EditModal = ({ refetchBudget, isEditMode, toggleEdit }) => {
  const { editModal, editBudgetData } = useBudget();
  const [loading, setLoading] = useState(false);
  const [recurringDisabled, setRecurringDisabled] = useState(true); // To control Recurring switch state
  const [showLabels, setShowLabels] = useState({
    from_label: false,
    to_label: false,
  });
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [occurrenceDatePickerVisible, setOccurrenceDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const occurrenceDatePickerRef = useRef(null);
  const { refetch: refetchGetData } = useGetApi({ url: `/api/getAllNotification`, options: { refetchOnWindowFocus: false, enabled: false } });
  const ethereumAddressPattern = /\b0x[a-fA-F0-9]{40}\b/;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      from_account: "",
      to_account: "",
      from_label: "",
      to_label: "",
      amount: 0,
      is_executed: false,
      is_recurring: false,
      date: "",
      // nextOccurrenceDate: "",
      comments: "",
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    const apiEndpoint = isEditMode ? updateBudgetApi : createBudgetApi;

    await performMutation(
      apiEndpoint,
      data,
      () => {
        refetchBudget();
        reset();
        toggleEdit();
        setTimeout(() => {
          refetchGetData();
        }, 500);
      },
      () => {
        setLoading(false);
      }
    );
    setLoading(false);
  };

  const handleChange = (field, value, e) => {
    if (field === "date") {
      setRecurringDisabled(!value);
      if (!value) setValue("is_recurring", false);
    }

    if (field === "is_executed" || field === "is_recurring") {
      setValue(field, e.target.checked ? true : false);
    }

    // if (field === "date") {
    //   setValue(field, value?.target?.value);

    //   if (!value) {
    //     // If the date is removed, disable the Recurring switch and set it to false
    //     setValue("is_recurring", false);
    //     setRecurringDisabled(true);
    //   } else {
    //     // Enable the Recurring switch if a date is selected
    //     setRecurringDisabled(false);
    //   }
    //   setDatePickerVisible(false);
    // }

    if (field === "nextOccurrenceDate") {
      setValue(field, value);
      setOccurrenceDatePickerVisible(false);
    }
  };

  const handleDateChange = (date) => {
    setValue("date", date);

    if (!date) {
      // If the date is removed, disable the Recurring switch and set it to false
      setValue("is_recurring", false);
      setRecurringDisabled(true);
    } else {
      // Enable the Recurring switch if a date is selected
      setRecurringDisabled(false);
    }
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };
  const toggleOccurrenceDatePicker = () => {
    setOccurrenceDatePickerVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setDatePickerVisible(false);
    }
    if (occurrenceDatePickerRef.current && !occurrenceDatePickerRef.current.contains(event.target)) {
      setOccurrenceDatePickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("from_account", editBudgetData.from_account);
      setValue("to_account", editBudgetData.to_account);
      setValue("from_label", editBudgetData.from_label);
      setValue("to_label", editBudgetData.to_label);
      setValue("amount", editBudgetData.amount);
      setValue("is_executed", editBudgetData.is_executed ? true : false);
      setValue("is_recurring", editBudgetData.is_recurring ? true : false);
      setValue("date", editBudgetData.date ? new Date(editBudgetData.date) : "");
      // setValue("nextOccurrenceDate", editBudgetData.next_occurrence_date ? new Date(editBudgetData.next_occurrence_date) : "");
      setValue("comments", editBudgetData?.comments);

      if (editBudgetData.from_label) setShowLabels((prev) => ({ ...prev, from_label: true }));
      if (editBudgetData.to_label) setShowLabels((prev) => ({ ...prev, to_label: true }));

      // Disable Recurring switch if there's no date set
      setRecurringDisabled(!editBudgetData.date);
    } else {
      reset();
      setRecurringDisabled(true); // Disable Recurring switch on reset
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Budget" : "Create Budget"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* ================== From Account And Label Starts ==================  */}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Account:</label>
                <input
                  {...register("from_account", {
                    required: "From account is required",
                  })}
                  className={`form-control search-transaction ${errors.from_account ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Enter from account address"
                  disabled={isEditMode}
                />
                {isEditMode &&
                  !errors?.from_account &&
                  !watch("from_label") &&
                  //  !showLabels?.from_label &&
                  (!showLabels?.from_label ? (
                    <p className="text-info" onClick={() => setShowLabels({ ...showLabels, from_label: true })}>
                      <i className="fa-solid fa-circle-info"></i> Want to add <b>from account label </b> also?
                    </p>
                  ) : (
                    <p className="text-danger" onClick={() => setShowLabels({ ...showLabels, from_label: false })}>
                      <i className="fa-regular fa-circle-xmark"></i> No need to add <b>from account label </b>?
                    </p>
                  ))}
                {errors.from_account && <div className="invalid-feedback">{errors.from_account.message}</div>}
              </FormGroup>
            </Col>

            {(showLabels.from_label || watch("from_label")) && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">From Label:</label>
                  <input {...register("from_label")} className={`form-control search-transaction `} type="text" placeholder="Enter from label" />
                </FormGroup>
              </Col>
            )}
            {/* ================== From Account And Label Ends ==================  */}

            {/* ================== To Account And Label Start ==================  */}
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">To Account:</label>
                <input
                  {...register("to_account", {
                    required: "To account is required",
                  })}
                  className={`form-control search-transaction ${errors.to_account ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Enter to account address"
                  disabled={isEditMode}
                />
                {isEditMode &&
                  !errors?.to_account &&
                  !watch("to_label") &&
                  // !showLabels.to_label &&
                  (!showLabels.to_label ? (
                    <p className="text-info" onClick={() => setShowLabels({ ...showLabels, to_label: true })}>
                      <i className="fa-solid fa-circle-info"></i> Want to add <b>to account label </b> also?
                    </p>
                  ) : (
                    <p className="text-danger" onClick={() => setShowLabels({ ...showLabels, to_label: false })}>
                      <i className="fa-regular fa-circle-xmark"></i> No need to add <b>to account label </b>?
                    </p>
                  ))}
                {errors.to_account && <div className="invalid-feedback">{errors.to_account.message}</div>}
              </FormGroup>
            </Col>

            {(showLabels.to_label || watch("to_label")) && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">To Label:</label>
                  <input {...register("to_label")} className={`form-control search-transaction `} type="text" placeholder="Enter to label" />
                </FormGroup>
              </Col>
            )}
            {/* ================== To Account And Label Ends ==================  */}

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Amount:</label>
                <input {...register("amount", { required: "Amount is required" })} className={`form-control search-transaction ${errors.amount ? "is-invalid" : ""}`} type="number" placeholder="Enter amount" />
                {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Date :</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker
                    // {...register("date", { required: "Date is required" })}
                    // {...register("date"),}
                    // showMonthDropdown
                    showYearDropdown
                    selected={watch("date")}
                    className={`form-control `}
                    placeholderText="Select date"
                    onChange={handleDateChange}
                    minDate={new Date()}
                    open={datePickerVisible} // Control visibility
                    // onInputClick={toggleDatePicker}
                  />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
                {/* {errors.date && <div className="invalid-feedback d-block">{errors.date.message}</div>} */}
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Comments:</label>
                <textarea {...register("comments")} className={`form-control search-transaction `} placeholder="Enter comments" />
              </FormGroup>
            </Col>

            <Col sm="3" className={`d-flex align-items-center `}>
              <div className="check-input gap-2">
                <label htmlFor="executed-switch" className="form-label mb-0">
                  Executed
                </label>
                <FormGroup switch>
                  <Input type="switch" role="switch" id="executed-switch" onChange={(e) => handleChange("is_executed", false, e)} checked={watch("is_executed")} />
                </FormGroup>
              </div>
            </Col>

            {!isEditMode && watch("date") && (
              <Col sm="3" className="d-flex align-items-center">
                <div className="check-input gap-2">
                  <label htmlFor="recurring-switch" className="form-label mb-0">
                    Recurring
                  </label>
                  <FormGroup switch>
                    <Input type="switch" role="switch" id="recurring-switch" onChange={(e) => handleChange("is_recurring", false, e)} checked={watch("is_recurring")} disabled={recurringDisabled} />
                  </FormGroup>
                </div>
              </Col>
            )}
            {isEditMode && (
              <Col sm="3" className="d-flex align-items-center">
                <div className="check-input gap-2">
                  <label htmlFor="recurring-switch" className="form-label mb-0">
                    Recurring
                  </label>
                  <FormGroup switch>
                    <Input type="switch" role="switch" id="recurring-switch" onChange={(e) => handleChange("is_recurring", false, e)} checked={watch("is_recurring")} disabled={recurringDisabled} />
                  </FormGroup>
                </div>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={loading}>
            {isEditMode ? "Update" : "Create"}
            {loading && <i className="fa-solid fa-circle-notch fa-spin ms-2"></i>}
          </Button>
          <Button
            color="light"
            className="border border-1"
            onClick={() => {
              toggleEdit();
              reset();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;

// //     {isEditMode && (
//   <Col sm="6">
//   <FormGroup className="form-group">
//     <label className="mb-2 form-label">Next Occurrence Date :</label>
//     {/* <div className="calander-icon">
//     <DatePicker {...register("date")} selected={watch("date")} className="form-control" placeholderText="Select date" onChange={handleDateChange} />
//     <i className="fa-solid fa-calendar-days" />
//   </div> */}
//     <div className="calander-icon" ref={occurrenceDatePickerRef}>
//       <DatePicker
//         {...register("nextOccurrenceDate")}
//         selected={watch("nextOccurrenceDate")}
//         className="form-control"
//         placeholderText="Select date"
//         onChange={(e) => handleChange("nextOccurrenceDate", e)}
//         minDate={new Date()}
//         open={occurrenceDatePickerVisible} // Control visibility
//         // onInputClick={toggleDatePicker}
//       />
//       <i className="fa-solid fa-calendar-days" onClick={toggleOccurrenceDatePicker} />
//     </div>
//     {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
//   </FormGroup>
// </Col>
// )}
