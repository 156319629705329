import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { getBudgetApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useBudget } from "../../Context/BudgetContext";
import useDebounce from "../../Hooks/useDebounce";
import CommonPagination from "../Common/CommonPagination";
import PageLimitDropdown from "../Common/PageLimitDropdown";
import BudgetDataTable from "./BudgetDataTable";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";
import ImportModal from "./ImportModal";

const BudgetContainer = () => {
  // const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  // const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const { editModal, importModal, toggleEdit, searchTerm, deleteModal } = useBudget();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [editMode, setEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  const getParams = () => {
    let params = { page, limit, search, sortOrder: sortOrder ? "asc" : "desc", sortColumn };
    if (selectedItem === "Recurring") {
      params.is_recurring = 1;
    } else if (selectedItem === "Not Recurring") {
      params.is_recurring = 0;
    }
    return params;
  };

  // const params = { page, limit, search, sortOrder: sortOrder ? "asc" : "desc", sortColumn };

  const { isLoading: isLoadingGetData, data: budgetData, refetch: refetchGetData } = useGetApi({ url: `/${getBudgetApi}`, params: getParams(), options: { refetchOnWindowFocus: false, enabled: true } });

  useEffect(() => {
    if (budgetData) {
      setTotalPages(budgetData.totalPages);
    }
  }, [budgetData]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    if (search.trim()?.length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  const addBudget = () => {
    setEditMode(false);
    toggleEdit();
  };

  return (
    <Fragment>
      <section className="common-table">
        <Row className="mb-3 g-3">
          <Col>
            <PageLimitDropdown limit={limit} setLimit={setLimit} setPage={setPage} />
          </Col>
          <Col className="search-box">
            <div className="common-flex">
              <p className="mb-0">Filter Recurring :</p>
              <div className="d-flex align-items-center">
                {/* <FormGroup switch>
                  <Input type="switch" role="switch" />
                </FormGroup> */}

                {/* <input type="text" className="form-control search-transaction" placeholder="Search ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target?.value)} /> */}
                <UncontrolledDropdown className="common-dropdown">
                  <DropdownToggle caret color="primary">
                    {" "}
                    {selectedItem}{" "}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleSelect("All")}>All</DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Recurring")}>Recurring</DropdownItem>
                    <DropdownItem onClick={() => handleSelect("Not Recurring")}>Not Recurring</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <Button color="primary border border-1" onClick={addBudget}>
              <Plus size={15} /> Create Budget
            </Button>
            {/* <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
              <div className="light-box" id="refresh-tooltip" onClick={() => handleRefresh(refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL)}>
                <i className="fa-solid fa-rotate"></i>
              </div>
            </Button>
            <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
              Refresh
            </Tooltip> */}
          </Col>
        </Row>
        <div className="custom-scrollbar table-responsive">
          <BudgetDataTable budgetData={budgetData} handleSort={handleSort} isLoadingGetData={isLoadingGetData} setEditMode={setEditMode} sortOrder={sortOrder} />
        </div>
        {!isLoadingGetData && budgetData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
      </section>
      {deleteModal && <DeleteModal refetchBudget={refetchGetData} />}
      {editModal && <EditModal refetchBudget={refetchGetData} isEditMode={editMode} toggleEdit={toggleEdit} />}
      {importModal && <ImportModal refetchGetData={refetchGetData} />}
    </Fragment>
  );
};

export default BudgetContainer;
