import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { ChevronLeft, Mail } from "react-feather";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { useAuth } from "../../../Context/AuthContext";

const ForgotPasswordContainer = () => {
  const [forgotSpin, SetForgotSpin] = useState(false);
  const { forgotPasswordSchema, handleReset } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) });
  const onSubmit = (data) => handleReset({ data, navigate, SetForgotSpin });

  return (
    <Form className="auth-card" onSubmit={handleSubmit(onSubmit)} noValidate>
      <h2>Forgot Password</h2>
      <div className="auth-content">
        <FormGroup className="form-group">
          <Label>Email</Label>
          <div className="position-relative">
            <Mail />
            <input className="form-control" {...register("email")} type="email" placeholder="Enter Email" />
          </div>
          {errors.email && <span className="text-danger fs-6">{errors.email.message}</span>}
        </FormGroup>
        <FormGroup className="mb-0 mt-3 text-center loader-btn">
          <Button disabled={forgotSpin ? true : false} type="submit" className="btn-solid mb-3">
            Verify {forgotSpin && <div className="loader"></div>}
          </Button>
          <Link to={`${process.env.PUBLIC_URL}/login`}>
            <ChevronLeft size={17} className="me-1" />
            Back to Sign in
          </Link>
        </FormGroup>
      </div>
    </Form>
  );
};

export default ForgotPasswordContainer;
