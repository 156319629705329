import React from "react";
import { handleLimitChange } from "./CustomFunctions";

const PageLimitDropdown = ({ limit, setLimit, setPage }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <span>shows :</span>
      <select value={limit} onChange={(e) => handleLimitChange(e, setLimit, setPage)} type="select" className="form-select w-auto">
        {[25, 50, 75, 100].map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <span>Entries</span>
    </div>
  );
};

export default PageLimitDropdown;
