import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentBank = () => {
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <h4>Recent Bank Transfers</h4>
        </CardHeader>
        <CardBody className="pt-0">
          <Table className="data-transfer-table">
            <thead className="border-bottom">
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1234</td>
                <td>9876</td>
                <td>500</td>
              </tr>
              <tr>
                <td>2468</td>
                <td>1357</td>
                <td>750</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentBank;
