import React from "react";
import { Edit, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { useBudget } from "../../Context/BudgetContext";
import InnerLoader from "../../Layout/InnerLoader";
import { formatAmount, formatDateTime, handleCopyCode, truncateMiddle } from "../../Utils";
import CommonTooltip from "../Common/CommonTooltip";

const BudgetDataTable = ({ setEditMode, handleSort, sortOrder, isLoadingGetData, budgetData }) => {
  const { toggleEdit, setEditBudgetData, toggleDelete } = useBudget();

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = budgetData?.data.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditBudgetData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = budgetData?.data.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditBudgetData(singleEditData);
    }
  };

  return (
    <Table bordered className="account-table budget-table">
      <thead>
        <tr>
          <th className="head-text">
            <p className="header-name">No.</p>
          </th>
          <th className="head-text">
            <p className="header-name">From Account</p>
          </th>
          <th className="head-text">
            <p className="header-name">To Account</p>
          </th>
          <th className="head-text">
            <p className="header-name">Amount</p>
          </th>
          <th className="head-text">
            <p className="header-name">Date</p>
          </th>
          <th className="head-text">
            <p className="header-name">Comments</p>
          </th>
          <th className="head-text">
            <p className="header-name">Executed</p>
          </th>
          <th className="head-text">
            <p className="header-name">Recurring</p>
          </th>
          {/* <th className="head-text">
            <p className="header-name">Next Occurrence Date </p>
          </th> */}

          <th className="head-text">
            <p className="header-name">Action</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoadingGetData ? (
          <tr>
            <td colSpan="10" className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : (
          <>
            {budgetData?.data?.length > 0 ? (
              budgetData?.data?.map((item, i) => (
                <tr key={item?.id}>
                  <td scope="row">{i + 1}</td>
                  <td>
                    <div className="copy-account">
                      {item?.from_label ? (
                        <CommonTooltip placement="right" id={`TooltipExample-from-${item?.id}`} content={item?.from_account} color="danger" redirectLink={"https://avail.subscan.io/account/"}>
                          {item?.from_label}
                        </CommonTooltip>
                      ) : (
                        <Link to={`https://avail.subscan.io/account/${item?.from_account}`} target="_blank">
                          {truncateMiddle(item?.from_account, 12)}
                        </Link>
                      )}
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.from_account)} />
                    </div>
                  </td>
                  <td>
                    <div className="copy-account">
                      {item?.to_label ? (
                        <CommonTooltip placement="right" id={`TooltipExample-from-${item?.id}`} content={item?.to_account} color="success" redirectLink={"https://avail.subscan.io/account/"}>
                          {item?.to_label}
                        </CommonTooltip>
                      ) : (
                        <Link to={`https://avail.subscan.io/account/${item?.to_account}`} target="_blank">
                          {truncateMiddle(item?.to_account, 12)}
                        </Link>
                      )}
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.to_account)} />
                    </div>
                  </td>
                  <td>{formatAmount(item?.amount)}</td>
                  <td>{item?.date ? formatDateTime(item?.date) : "No date added"}</td>
                  <td>{item?.comments || "---"}</td>
                  <td> {item?.is_executed ? <i className="fa-solid fa-circle-check treasury-right"></i> : <i className="fa-solid fa-circle-xmark treasury-cancel"></i>}</td>
                  <td> {item?.is_recurring ? <i className="fa-solid fa-circle-check treasury-right"></i> : <i className="fa-solid fa-circle-xmark treasury-cancel"></i>}</td>
                  {/* <td>{item?.is_recurring ? formatDateTime(item?.next_occurrence_date) : "---"}</td> */}
                  <td>
                    <div className="common-flex justify-content-center">
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center" onClick={() => handleEdit(item?.id)}>
                          <Edit size={20} />
                        </Button>
                      </div>
                      <div className="outer-box">
                        <Button color="transparent" className="flex-center trash-icon" onClick={() => handleDelete(item?.id)}>
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan="10" className="text-center no-found">
                  No data found
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default BudgetDataTable;
