import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import RedirectRoute from "./RedirectRoute";
import { authRoutes, protRoutes } from "./Routes";
import Layout from "../Layout/Layout";

const Routers = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        {authRoutes.map(({ path, element }, i) => (
          <Route key={i} path={path} element={<RedirectRoute>{element}</RedirectRoute>} />
        ))}
        {protRoutes.map(({ path, element }, i) => (
          <Route element={<Layout />}>
            <Route key={i} path={path} element={<ProtectedRoute>{element}</ProtectedRoute>} />
          </Route>
        ))}
        <Route path="/" element={<Navigate to={isAuthenticated ? "/transaction" : "/login"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
