import React from "react";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Table } from "reactstrap";
import { useBridgerTransfer } from "../../Context/BridgeTransferContext";
import InnerLoader from "../../Layout/InnerLoader";
import { formatDateTime, handleCopyCode, truncateMiddle } from "../../Utils";
import CommonTooltip from "../Common/CommonTooltip";

const BridgeTransferDataTable = ({ isLoadingGetData, transactionData }) => {
  const { toggleEdit, setEditBridgeTransferData } = useBridgerTransfer();

  const handleEdit = (id) => {
    const singleEditData = transactionData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditBridgeTransferData(singleEditData);
    }
  };
  return (
    <Table bordered className="transaction-table">
      <thead>
        <tr>
          <th className="head-text">
            <p className="header-name">
              No.
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>

          <th className="head-text">
            <p className="header-name">
              Hash
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">From Account</p>
          </th>
          <th className="head-text">
            <p className="header-name">To Account</p>
          </th>
          {/* <th className="head-text" >
            <p className="header-name">
              Amount
              {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>}
            </p>
          </th> */}
          <th className="head-text">
            <p className="header-name">
              Timestamp
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
          <th className="head-text">
            <p className="header-name">Action</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {/* {isLoadingGetData ? (
          <tr>
            <td colSpan="10" className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : (
          <>
            {transactionData?.data?.length > 0 ? (
              transactionData.data.map((item, i) => (
                <tr key={item.id}>
                  <td scope="row">{item.id}</td>

                  <td>
                    <Link to={`https://avail.subscan.io/extrinsic/${item?.extrinsic_id}`} target="_blank">
                      {item?.extrinsic_id}
                    </Link>
                  </td>
                  <td>
                    <div className="copy-account">
                      {item.from_label ? (
                        <CommonTooltip placement="right" id={`TooltipExample-from-${item.id}`} content={item.from_account} color="danger" redirectLink={"https://avail.subscan.io/account/"}>
                          {item.from_label}
                        </CommonTooltip>
                      ) : (
                        <Link to={`https://avail.subscan.io/account/${item?.from_account}`} target="_blank">
                          {truncateMiddle(item.from_account, 10)}
                        </Link>
                      )}
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.from_account)} />
                    </div>
                  </td>
                  <td>
                    <div className="copy-account">
                      {item.to_label ? (
                        <CommonTooltip placement="right" id={`TooltipExample-to-${item.id}`} content={item.to_account} color="success" redirectLink={"https://avail.subscan.io/account/"}>
                          {item.to_label}
                        </CommonTooltip>
                      ) : (
                        <Link to={`https://avail.subscan.io/account/${item?.to_account}`} target="_blank">
                          {truncateMiddle(item.to_account, 10)}
                        </Link>
                      )}
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.to_account)} />
                    </div>
                  </td>
                  <td>{formatDateTime(item.timestamp)}</td>
                  <td>
                    <div className="transaction-btn">
                      <Button color="transparent" className="flex-center" onClick={() => handleEdit(item.id)}>
                        <Edit size={20} color="black" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan="10" className="text-center no-found">
                  No data found
                </td>
              </tr>
            )}
          </>
        )} */}
      </tbody>
    </Table>
  );
};

export default BridgeTransferDataTable;
