// Auth
export const loginApi = "api/auth/login";
export const forgotPasswordApi = "api/auth/forgotpassword";
export const verifyEmailTokenApi = "api/auth/verifyEmail";
export const resetPasswordApi = "api/auth/resetpassword";

// Transaction
export const transactionApi = "api/getTransactions";
export const updateTransactionApi = "api/transation/update";
export const importTransactionApi = "api/import/transaction";

// Accounts
export const updateAccountApi = "api/account/update";
export const importAccountApi = "api/import/account";
export const refreshAccountApi = "api/transaction/refresh";
export const createAccountApi = "api/account/create";
export const deleteAccountApi = "api/account/delete";

// Budget
export const getBudgetApi = "api/getBudgets";
export const createBudgetApi = "api/createBudget";
export const updateBudgetApi = "api/updateBudget";
export const deleteBudgetApi = "api/deleteBudget";

// Noti