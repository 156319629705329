import React, { Fragment, useEffect } from "react";
import { useGetApi } from "../../Api/useGetApi";
import NotificationsTable from "./NotificationsTable";

const Notifications = () => {
  const { isLoading: isLoadingGetData, data: notificationsData, refetch: refetchGetData } = useGetApi({ url: `/api/getAllNotification`, options: { refetchOnWindowFocus: false, enabled: false } });
  const { refetch: refetchReadAllNotificationsData } = useGetApi({ url: `/api/markAsReadNotification`, options: { refetchOnWindowFocus: false, enabled: false } });

  useEffect(() => {
    refetchGetData()
      .then((res) => {
        const isAllNotificationRead = res?.data?.data?.filter((data) => !data?.mark_as_read);
        if (isAllNotificationRead?.length) {
          refetchReadAllNotificationsData().then(() =>
            setTimeout(() => {
              refetchGetData();
            }, 2000)
          );
        } else {
          return;
        }
      })
      .catch((err) => {
        return err;
      });
  }, []);

  return (
    <Fragment>
      <section className="common-table">
        <div className="custom-scrollbar table-responsive">
          <NotificationsTable isLoadingGetData={isLoadingGetData} notificationsData={notificationsData} refetch={refetchGetData} />
        </div>
        {/* {!dummyNotificationsData && dummyNotificationsData?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />} */}
      </section>
    </Fragment>
  );
};

export default Notifications;
