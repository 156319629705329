import React from "react";
import { Badge, Table } from "reactstrap";
import InnerLoader from "../../Layout/InnerLoader";

const NotificationsTable = ({ notificationsData, setEditMode, handleSort, sortOrder, isLoadingGetData, refetch }) => {
  // const { toggleEdit, setEditAccountData, toggleDelete } = useAccount();

  // const handleEdit = (id) => {
  //   setEditMode(true);
  //   const singleEditData = accountData?.data?.find((item) => item?.id === id);
  //   if (singleEditData) {
  //     toggleEdit();
  //     setEditAccountData(singleEditData);
  //   }
  // };

  // const handleDelete = (id) => {
  //   const singleEditData = accountData?.data?.find((item) => item?.id === id);
  //   if (singleEditData) {
  //     toggleDelete();
  //     setEditAccountData(singleEditData);
  //   }
  // };
  return (
    <Table bordered className="notification-table">
      <thead>
        <tr>
          <th className="head-text">
            <p className="header-name">
              No.
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>

          <th className="head-text">
            <p className="header-name">Notification</p>
          </th>
          <th className="head-text">
            <p className="header-name">
              Status
              {/* {sortOrder ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>} */}
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoadingGetData ? (
          <tr>
            <td colSpan="10" className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : (
          <>
            {notificationsData?.data?.length > 0 ? (
              notificationsData?.data?.map((item, i) => (
                <tr key={item.id}>
                  <td scope="row">{i + 1}</td>
                  <td className={`${item?.mark_as_read ? "notify-push" : "notify-visible"}`}>
                    <p className="fw-500"> {item.title} </p>
                    <span> {item.description}</span>
                  </td>
                  <td>
                    <Badge color={item?.status ==='completed' ? "success" : "warning"}> {item?.status==='completed' ? "Executed" : "Pending"} </Badge>
                  </td>

                  {/* <td>
                    {item.label ? (
                      <CommonTooltip placement="right" id={`TooltipExample-from-${item.id}`} content={item.address} color="danger">
                        {item.label}
                      </CommonTooltip>
                    ) : (
                      item.label
                    )}
                  </td> */}
                  {/* <td>
                    <div className="copy-account">
                      <Link to={`https://avail.subscan.io/account/${item.address}`} target="_blank">
                        {truncateMiddle(item.address, 12)}
                      </Link>
                      <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.address)} />
                    </div>
                  </td>
                  <td>{formatAmount(item.spend)}</td> */}
                  {/* <td>
                    {
                      <CommonTooltip
                        isBadge={true}
                        placement="right"
                        id={`Tooltip-balance-${item.id}`}
                        content={
                          <Fragment>
                            <span>
                              <strong>Locked: </strong> {item?.amountFrozenRounded}
                            </span>
                            <span>
                              <strong>Amount:</strong> {item?.amountRounded}
                            </span>
                            <span>
                              <strong>Total:</strong> {item?.amountTotalRounded}
                            </span>
                          </Fragment>
                        }
                      >
                        <u> {item?.amountTotalRounded ? formatAmount(item?.amountTotalRounded) : item?.amountTotalRounded}</u>
                      </CommonTooltip>
                    }
                  </td> */}
                </tr>
              ))
            ) : (
              <tr className="">
                <td colSpan="10" className="text-center no-found">
                  No data found
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default NotificationsTable;
