import axios from "axios";
import toast from "react-hot-toast";

export const performMutation = async (url, data, onSuccess, onError) => {
  try {
    const token = localStorage.getItem("finance_token");
    const parsedToken = JSON.parse(token);
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {
      headers: {
        "x-access-token": parsedToken,
      },
    });

    if (response.status === 200) {
      if (onSuccess) onSuccess(response.data);
      toast.success(response?.data.message);
    }
  } catch (error) {
    if (onError) onError(error);
    toast.error(`${error?.response?.data?.message || error.message}`);
  }
};
