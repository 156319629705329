import React from "react";  
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";  

const CommonPagination = ({ totalPages, currentPage, setCurrentPage }) => {  
  const handlePageChange = (page) => {  
    setCurrentPage(page);  
    window.scrollTo({ top: 0, behavior: 'smooth' });  
  }  

  const handlePreviousButton = () => {  
    if (currentPage !== 1) {  
      handlePageChange(currentPage - 1);  
    }  
  };  

  const handleNextButton = () => {  
    if (currentPage !== totalPages) {  
      handlePageChange(currentPage + 1);  
    }  
  };  

  const renderPageNumbers = () => {  
    const pageNumbers = [];  
    if (totalPages <= 6) {  
      for (let i = 1; i <= totalPages; i++) {  
        pageNumbers.push(  
          <PaginationItem key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>  
            <PaginationLink>{i}</PaginationLink>  
          </PaginationItem>  
        );  
      }  
    } else {  
      pageNumbers.push(  
        <PaginationItem key="page-1" active={1 === currentPage} onClick={() => handlePageChange(1)}>  
          <PaginationLink>1</PaginationLink>  
        </PaginationItem>  
      );  

      if (currentPage > 3) {  
        pageNumbers.push(  
          <PaginationItem key="start-ellipsis" disabled>  
            <PaginationLink>...</PaginationLink>  
          </PaginationItem>  
        );  
      }  

      const startPage = Math.max(2, currentPage - 1);  
      const endPage = Math.min(totalPages - 1, currentPage + 1);  

      for (let i = startPage; i <= endPage; i++) {  
        pageNumbers.push(  
          <PaginationItem key={`page-${i}`} active={i === currentPage} onClick={() => handlePageChange(i)}>  
            <PaginationLink>{i}</PaginationLink>  
          </PaginationItem>  
        );  
      }  

      if (currentPage < totalPages - 2) {  
        pageNumbers.push(  
          <PaginationItem key="end-ellipsis" disabled>  
            <PaginationLink>...</PaginationLink>  
          </PaginationItem>  
        );  
      }  

      pageNumbers.push(  
        <PaginationItem key={`page-${totalPages}`} active={totalPages === currentPage} onClick={() => handlePageChange(totalPages)}>  
          <PaginationLink>{totalPages}</PaginationLink>  
        </PaginationItem>  
      );  
    }  
    return pageNumbers;  
  };  

  return (  
    <Pagination className="d-flex justify-content-end">  
      <PaginationItem onClick={handlePreviousButton} disabled={currentPage === 1}>  
        <PaginationLink className="back-pagination">Back</PaginationLink>  
      </PaginationItem>  
      {renderPageNumbers()}  
      <PaginationItem onClick={handleNextButton} disabled={currentPage === totalPages}>  
        <PaginationLink className="next-pagination">Next</PaginationLink>  
      </PaginationItem>  
    </Pagination>  
  );  
};  

export default CommonPagination;