import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useAccount } from "../../Context/AccountContext";

const DeleteModal = ({ refetchTransaction }) => {
  const [loading, setLoading] = useState(false); // State to track loading status
  const { toggleDelete, deleteModal, editAccountData } = useAccount();

  const handleDelete = async () => {
    setLoading(true); 
    try {
      const token = localStorage.getItem("finance_token");
      const parsedToken = JSON.parse(token);
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/account/delete`, {
        headers: {
          "x-access-token": parsedToken,
        },
        data: { id: editAccountData.id },
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        refetchTransaction();
        toggleDelete();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={deleteModal} toggle={toggleDelete} className="account-deletion" centered>
      <ModalBody>
        <div className="trash-box">
          <i className="fa-solid fa-trash-can"></i>
          <h3>Do you really want to remove this account?</h3>
          <p>This operation will permanently remove the item and is not reversible. Are you sure you want to proceed?</p>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <div className="common-btn-box">
          <Button color="danger" onClick={handleDelete} disabled={loading}>
            Delete {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="secondary" onClick={toggleDelete} disabled={loading}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
