import toast from "react-hot-toast";

// Refresh Api Data
// export const handleRefresh = (refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL) => {
//   try {
//     refreshData().then((response) => {
//       if (response && response?.data?.message) {
//         toast.success(response.data?.message);
//       }
//       setTimeout(() => {
//         refetchGetData();
//       }, 1000);
//     });

//     const now = new Date().getTime();
//     localStorage.setItem("lastRefreshTime", now);
//     setIsRefreshDisabled(true);

//     setTimeout(() => {
//       setIsRefreshDisabled(false);
//     }, REFRESH_INTERVAL);
//   } catch (err) {
//     toast.error(err);
//   }
// };

export const handleRefresh = (refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime) => {
  const now = new Date().getTime();

  // Set the tooltip content to "Refreshing..." immediately
  setTooltipContent("Refreshing...");

  // Store the refresh time in local storage
  localStorage.setItem("lastRefreshTime", now);

  // Perform the refresh (refetch the data)
  refreshData().then((response) => {
    if (response && response?.data?.message) {
      toast.success(response.data?.message);
    }
    setTimeout(() => {
      refetchGetData();
    }, 1000);
  });

  // Disable the refresh button
  setIsRefreshDisabled(true);

  // Set the interval to update the tooltip with countdown
  const intervalId = setInterval(() => {
    const remainingTime = calculateRemainingTime(now);
    if (remainingTime) {
      setTooltipContent("Refresh available in");
      setRemainingTime(remainingTime);
    } else {
      setIsRefreshDisabled(false);
      setTooltipContent("Refresh");
      clearInterval(intervalId);
    }
  }, 1000);
};

// Change Page Data Limit
export const handleLimitChange = (e, setLimit, setPage) => {
  setLimit(Number(e.target.value));
  setPage(1);
};
